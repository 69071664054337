import { UUID } from "crypto";

export const toProductScreen = (
  strings: TemplateStringsArray,
  slug: string,
  id: UUID
) => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const idd = regex.test(id) ? id : regex.test(slug) ? slug : "";
  const slugg = !regex.test(id) ? id : !regex.test(slug) ? slug : "";
  return `/product/${slugg}?pid=${idd}`;
};

// function tag(strings: TemplateStringsArray, ...values: string[]) {
//   return (
//     strings.raw[0] +
//     values.map((value, i) => `<b>${value}<b/>` + strings.raw[i + 1]).join("")
//   );
// }
// const ss = "daa";
// const name = "Alice";
// const age = 25;

// const message = tag`Hello, ${name}! You are ${age} years old.  aryou dumb ${ss} da poda ${"chekka"}`;
// console.log(message);
