import React from "react";
import { Helmet } from "react-helmet";

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
}

const SEO: React.FC<SEOProps> = ({ title, description, image }) => {
  return (
    <Helmet
      title={title ?? "NextMe"}
      meta={[
        {
          name: `description`,
          content: description ?? "Nextme Website",
        },
        {
          property: `og:title`,
          content: title ?? "Nextme",
        },
        {
          property: `og:description`,
          content: description ?? "Nextme Website",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content:
            image ?? "https://bairuha-bucket.s3.ap-south-1.amazonaws.com/nextmiddleeast/mail_assets/logo.png",
        },
      ]}
    ></Helmet>
  );
};

export default SEO;
