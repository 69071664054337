import { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { Row, Col, Container } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextArea from "antd/es/input/TextArea";
import Loading from "../../../components/loading";
import React from "react";
import { useSelector } from "react-redux";
function EditProductInformations(props: any) {
  const [subCategories, setSubCategories] = useState<any>([]);
  const [specifications, setSpecifications] = useState(
    props?.data?.description
  );
  const categories = useSelector((state: any) => state.Category?.categries);
  const [form] = Form.useForm();
  const categoryChangeHandler = (value: any) => {
    if (Array.isArray(categories)) {
      const index: any = categories?.findIndex((item: any) => item.id == value);
      if (index != -1) {
        let item = categories[index]?.sub_categories;
        setSubCategories(item);
      }
    }
  };
  const submit = async (values: any) => {
    try {
      let obj = values;
      obj["specifications"] = specifications;
      props.onChange(obj);
    } catch (err) {
      console.log("err", err);
    }
  };
  //get categories=============================================================
  useEffect(() => {
    form.setFieldsValue({
      bar_code: props?.data?.bar_code,
      sku: props?.data?.sku,
      brand: props?.data?.brand,
      bulk_order: props?.data?.bulk_order == true ? "accept" : "notaccept",
      category: String(props?.data?.category),
      manufacture: props?.data?.manufacture,
      purchase_rate: props?.data?.purchase_rate,
      retail_rate: props?.data?.retail_rate,
      status: props?.data?.status == true ? "available" : "notavailable",
      subCategory: String(props?.data?.subCategory),
      name: props?.data?.name,
      description: props?.data?.description,
      unit: props?.data?.unit,
      units: props?.data?.units,
    });
    setSpecifications(props?.data?.specifications);
  }, [props?.data]);
  useEffect(() => {
    categoryChangeHandler(props?.data?.category);
  }, [props?.data?.category]);
  return (
    <Container>
      {props?.productLoading ? (
        <Loading />
      ) : (
        <Form onFinish={submit} form={form}>
          <Row>
            <Col sm={6}>
              <div className="input-form-label">Category *</div>
              <Form.Item
                name={"category"}
                rules={[{ required: true, message: "Please select Category" }]}
              >
                <Select
                  disabled
                  style={{ width: "100%" }}
                  className="border rounded"
                  allowClear
                  onChange={categoryChangeHandler}
                  placeholder="Select category"
                  defaultValue={"hellooo"}
                >
                  {categories?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="input-form-label">Sub Category *</div>
              <Form.Item
                name={"subCategory"}
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  className="border rounded"
                  allowClear
                  placeholder="Select Subcategory"
                >
                  {subCategories?.map((item: any, index: number) => (
                    <Select.Option key={index} value={item._id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <div className="input-form-label">Product Title *</div>
              <Form.Item
                name={"name"}
                rules={[
                  { required: true, message: "" },
                  { max: 200, message: "Product name length is too much" },
                ]}
              >
                <Input placeholder="Title" />
              </Form.Item>
              <div className="input-form-label">Description *</div>
              <Form.Item
                name={"description"}
                rules={[
                  { required: true, message: "" },
                  { max: 250, message: "Product Description is too long" },
                ]}
              >
                <TextArea rows={4} placeholder="Description" maxLength={250} />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <div className="input-form-label">Brand</div>
              <Form.Item name={"brand"}>
                <Input placeholder="Brand Name" />
              </Form.Item>
              <div className="input-form-label">Manufactor</div>
              <Form.Item name={"manufacture"}>
                <Input placeholder="Manufactor" />
              </Form.Item>
              <Row>
                <Col sm={6}>
                  <div className="input-form-label">Product SKU *</div>
                  <Form.Item
                    name={"sku"}
                    rules={[
                      { required: true, message: "" },
                      {
                        max: 16,
                        message: "Please choose a value less than 16 chars",
                      },
                    ]}
                  >
                    <Input placeholder="sku" disabled />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Barcode</div>
                  <Form.Item
                    name={"bar_code"}
                    rules={[
                      { required: true, message: "" },
                      {
                        max: 16,
                        message: "Please choose a value less than 16 chars",
                      },
                    ]}
                  >
                    <Input placeholder="Barcode" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="input-form-label">Purchase Price</div>
                  <Form.Item
                    name={"purchase_rate"}
                    rules={[{ required: true, message: "" }]}
                  >
                    <Input placeholder="0.00" type="number" max={10000000} />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Retail Price</div>
                  <Form.Item
                    name={"retail_rate"}
                    rules={[{ required: true, message: "" }]}
                  >
                    <Input placeholder="0.00" type="number" max={10000000} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="input-form-label">Available Stock</div>
                  <Form.Item name={"unit"}>
                    <Input
                      placeholder="Available Stock"
                      type="number"
                      max={100000}
                      disabled={
                        props?.data?.productVariant?.length ? true : false
                      }
                    />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">
                    Available Unit for Bulk
                  </div>
                  <Form.Item name={"units"}>
                    <Input placeholder="Available" type="number" max={100000} />
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Accept bulk orders</div>
                  <Form.Item
                    name={"bulk_order"}
                    rules={[
                      {
                        required: true,
                        message:
                          "Please Select if you suppor Bulk order for this Product",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      className="border rounded"
                      allowClear
                      placeholder="Select Bulk Order"
                    >
                      <Select.Option value={"accept"}>Accept</Select.Option>
                      <Select.Option value={"notaccept"}>
                        Not Accept
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={6}>
                  <div className="input-form-label">Product Status</div>
                  <Form.Item
                    name={"status"}
                    rules={[
                      {
                        required: true,
                        message: "Please Choose the Status of The product",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      className="border rounded"
                      allowClear
                      placeholder="Select Status"
                    >
                      <Select.Option value={"available"}>
                        Available
                      </Select.Option>
                      <Select.Option value={"notavailable"}>
                        Not Available
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="input-form-label">
            Product Description and specifications
          </div>
          <div style={{ height: "270px" }}>
            <ReactQuill
              theme="snow"
              value={specifications}
              onChange={setSpecifications}
              style={{ height: "220px" }}
            />
          </div>

          <br />
          <Row>
            <Col sm={6}></Col>
            <Col sm={2}>
              {/* <Button size="large" block onClick={() => props?.onBack()}>
                Back
              </Button> */}
            </Col>
            <Col sm={4}>
              <Form.Item>
                <Button
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                  loading={props?.isLoading}
                >
                  Update Details
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
}
export default EditProductInformations;
